$(function(){
    /* index slider arrows
    ====================================================*/
    if($(window).width() < 480){
        $('#js-features-slider').slick({
            'prevArrow': $('#js-features-arrows__prev'),
            'nextArrow': $('#js-features-arrows__next'),
        });
    }

    /* index-tabs
    ====================================================*/
    $("#js-tabs .b-tabs__content__item").not(":first").hide();
    $("#js-tabs .b-tabs__list__item").click(function(e){
        //console.log($(this));
        e.preventDefault();
        $("#js-tabs .b-tabs__list__item").removeClass("active").eq($(this).index()).addClass("active");
        $("#js-tabs .b-tabs__content__item").hide().eq($(this).index()).fadeIn()
    }).eq(0).addClass("active");

    /* show tabs subitems min 1200
    ====================================================*/
    function showTabs(){
        let tabsContentItems = $('#js-tabs-content .b-tabs__content__item .row');
        let jsTabsBtn = tabsContentItems.find('.b-btn-tabs');


        jsTabsBtn.nextAll().hide();

        $('#js-tabs-content .b-btn-tabs').on('click', function(){
            let $this = $(this);
            $this.hide();
            $this.nextAll().fadeIn();
        });
    }

    if($(window).width() < 1200){
        showTabs();
    }

    $(window).resize(function(){
        if($(window).width() < 1200){
            showTabs();
        }else{
            $('#js-tabs-content .b-btn-tabs').nextAll().show();
        }
    });


    /* reviews slider
    ====================================================*/
    $("#js-reviews").slick({
        'slidesToShow': 2,
        'prevArrow': $('#js-arrows__prev'),
        'nextArrow': $('#js-arrows__next'),
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    /* reviews equal heights
    ====================================================*/
    $('#js-reviews .b-reviews__item__text').equalHeights();

    if($(window).width() < 500){
        $("#js-clients").slick({
            'slidesToShow': 1,
            'slidesToScroll': 1
        });
    }

    $(window).resize(function(){
        if($(window).width() < 500){
            $("#js-clients").slick({
                'slidesToShow': 2,
                'slidesToScroll': 2
            });
        }
    });
});